.serving-info {
    display: flex;
    align-items: center;
    gap: 5%;
    z-index: 3;
}

.prep-info {
    display: flex;
    gap: 15%;
}

.prep-info-parent {
    height: 90%;
    display: flex;
    flex-direction: column;
}

.serving-button {
    border-radius: 50% !important;
    background-color: #E4E4E4 !important;
}

.prep-info-second-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}