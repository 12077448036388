.dark-s1 {
  background-color:darkgreen;
  color:white;
}

.dark-s2 {
  background-color:darkblue;
  color:white;
}

.dark-s3 {
  background-color:darkgoldenrod;
  color:white;
}

.light-s1 {
  background-color:lightgreen;
}

.light-s2 {
  background-color:lightblue;
}

.light-s3 {
  background-color:lightgoldenrodyellow;
}

.step-top-box {
  position:relative;
  width:80px;
  padding:10px 20px;
  border-radius:5px;
}

.stream-header-box {
  padding:10px 20px;
  align-items:center;
  border-radius:10px;
}

.step-main-box {
  padding:15px 20px 10px;
  display:grid;
  max-width:340px;
  grid-template-columns: 40px auto;
  align-items:center;
  margin-top:-5px;
  z-index:-1;
  border-radius:5px;
}

.step-main-box > .do-icon > img {
  width:30px;
  height:auto;
}

.arrow {
  width:500px;
  position:fixed;
  z-index:100
}

.cool {
  fill: grey;
  stroke: grey;
}

.active{
  background-color: lightgrey;
}