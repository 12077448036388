.item-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item-row-parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.btn {
    width: 15% !important;
    height: 10vh !important;
    text-transform: none !important;
    border: #043F98 solid 3px !important;
    color: #043F98 !important;
    padding: 0 !important;
    border-radius: 9px !important;
}

.solid {
    font-weight: 700 !important;
}

.item-props {
    display: flex;
    gap: 10px;
    align-items: center;
}

.prep {
    display: flex;
    align-items: flex-end;
}

.title {
    font-weight: 700 !important;
}

.vegetables {
    color: #4B8F00 !important;
}

.spices {
    color: #CEAD1F !important;
}

.pantry {
    color: #573913 !important;
}

.dotted-line {
    border-bottom: 5px dotted #00000029;
    margin: 0 5%;
}

.dotted-line-hardware-bar {
    border-bottom: 1px dotted #707070;
    width: 73px;
}

.icon {
    max-width: 100%;
}

.padding-2 {
    padding-bottom: 2%;
}

.ingredient-list-parent {
    /* height: 90vh; */
    padding: 0 5%;
    box-sizing: border-box;
}

.ingredient-btn {
    padding: 0 5% !important;
    text-transform: none !important;
    border-radius: 20px !important;
    box-sizing: border-box;
}

.default-btn {
    color: black !important;
}

.add-border {
    border: 1px solid !important;
}

.green-btn {
    color: #4B8F00 !important;
    border-color: #4B8F00 !important;
}

.brown-btn {
    color: #573913 !important;
    border-color: #573913 !important;
}

.yellow-btn {
    color: #CEAD1F !important;
    border-color: #CEAD1F !important;
}

.ingredients-btn-parent {
    display: flex;
    justify-content: center;
    gap: 2%;
    width: 100%;
    position: fixed;
    background-color: #ffffff;
    padding: 30px 0;
    z-index: 2;
}

.ingredient-separator-parent {
    margin-top: 10%;
}

.prep-btn {
    border-radius: 50% !important;
    border: 3px solid #000000 !important;
    padding: 0 !important;
    height: 50px !important;
    width: 50px !important;
}

.prep-info-display {
    display: flex;
    gap: 10px;
    align-items: center;
}

.ingredients-only-margin {
    margin-top: 15% 
}