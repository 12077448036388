.start-cooking-btn {
    /* color: #ffffff !important; */
    /* background-color: #FD8D3D !important; */
    text-transform: none !important;
    border-radius: 10px !important;
    width: 420px !important;
    box-shadow: none !important;
}

.start-cooking-btn-child {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    width: 100%;
}

.title-recipe {
    position: absolute;
    z-index: 3;
    width: 100%;
}