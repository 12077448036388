.loader-container {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: relative;
}

.logo-container {
    position: absolute;
    margin-left: 30px;
}

.loader-parent {
    transform-origin: center;
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(100deg)
    }
    50% {
        transform: rotate(180deg)
    }
    100% {
        transform: rotate(360deg)
    }
    
}

.loader {
    stroke-dasharray: 1000;
    stroke-width: 10;;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap:round;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}