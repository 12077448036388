.fc-widget-normal,
.fc-mobile-view {
  left: 10px !important;
}


/* animated.zoomIn.faster, channel-title, channel-desc, u-agent-name */


.hotline-launcher.h-open .viewport div.h-conv div.fc-conversation-view div.h-header .title .channel-info .channel-title {
  font-size: 30px !important;
}

#fc_frame.h-open-container,
#fc_frame.fc-widget-normal.h-open-container {
  width: 600px !important;
}

.fbots-chat-header-title {
  font-size: 40px !important;
}

.chat-container [type=radio]:checked+label,
.chat-container [type=radio]:not(:checked)+label {
  font-size: 30px !important;
}